import React, { Component, Suspense } from 'react';

// Importing Section
const Navbar = React.lazy(() => import('../../component/Navbar/NavBar'));

const Section = React.lazy(() => import('./Section'));
const About = React.lazy(() => import('../../component/About'));
const Services = React.lazy(() => import('../../component/Services'));
const Feature = React.lazy(() => import('../../component/Feature'));
const Project = React.lazy(() => import('../../component/Project'));
const Clients = React.lazy(() => import('../../component/Clients'));
const User = React.lazy(() => import('../../component/User'));
const Footer = React.lazy(() => import('../../component/Footer/Footer'));
const Promo = React.lazy(() => import('../../component/Promo'));
const Visitors = React.lazy(() => import('../../component/Visitors'));
// const Privacy = React.lazy(() => import ('../../component/Privacy'))

class Layout1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: '/', navheading: 'Beranda', active: true, box: false },
        {
          id: 2,
          idnm: 'fitur',
          navheading: 'Fitur',
          active: false,
          box: false,
        },
        // {id:3, idnm:"artikel", navheading:"Artikel", active:false, box:false},
        {
          id: 4,
          idnm: 'kontak',
          navheading: 'Kontak',
          active: false,
          box: false,
        },
        // {
        //   id: 5,
        //   idnm: 'privacy',
        //   navheading: 'Layanan',
        //   active: false,
        //   box: false,
        // },
        {
          id: 6,
          idnm: 'register',
          navheading: 'Register',
          active: false,
          box: true,
        },
        // { id: 1, idnm: "home", navheading: "Home" },
        // { id: 2, idnm: "about", navheading: "About Us" },
        // { id: 3, idnm: "services", navheading: "Services" },
        // { id: 4, idnm: "features", navheading: "Features" },
        // { id: 5, idnm: "project", navheading: "Project" },
        // { id: 6, idnm: "clients", navheading: "Clients" },
        // { id: 7, idnm: "contact", navheading: "Contact Us" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: 'nav-sticky', imglight: false });
    } else {
      this.setState({ navClass: '', imglight: false });
    }
  };

  //set preloader div
  PreLoader = () => {
    return (
      <div id='preloader'>
        <div id='status'>
          <div className='spinner'>
            <div className='bounce1'></div>
            <div className='bounce2'></div>
            <div className='bounce3'></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />

          {/* Importing Section */}
          <Section />

          {/* Importing About */}
          <About />

          {/* Importing Service */}
          <Services />

          {/* Importing Feature */}
          <Feature />

          {/* Importing Project */}
          <Project />

          {/* Importing News */}
          {/* <User /> */}

          {/* Importing Client */}
          <Clients />

          {/* Import Promo */}
          <Promo />

          {/* Import Visitors */}
          <Visitors />
          
          {/* Import Privacy */}
          {/* <Privacy /> */}

          {/* Importing Footer */}
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default Layout1;
