import React, { useState } from 'react'
import { Card, Checkbox } from 'antd'

const NodePengemudi2 = () => {
  const [isAgreed, setIsAgreed] = useState(false)

  const handleCheckboxChange = (e) => {
    setIsAgreed(e.target.checked)
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%", padding: "20px" }}>
      <Card
        title="Pernyataan Persetujuan (Mitra Pengemudi)"
        bordered={false}
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "20px",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          margin: "0 auto",
          width: "100%",
          maxWidth: "600px", // Lebar maksimum untuk desktop
          textAlign: "left",
          fontSize: '12px',
        }}
      >
        <p>
          Mohon diperhatikan bahwa dengan menghapus akun Gaspoll Anda:
        </p>
        <ul>
          <li>Anda tidak akan lagi dapat mengakses fitur dan layanan yang ditawarkan melalui platform Gaspoll. Oleh karena itu, Anda mungkin ingin menyimpan catatan riwayat transaksi Anda dalam aplikasi GoPartner sebelum melanjutkan proses penghapusan akun.</li>
          <li>Jika Anda ingin menggunakan aplikasi Gaspoll lagi setelah menghapus akun Anda, Anda akan diminta untuk mendaftar akun baru.</li>
          <li>Kami berhak menyimpan beberapa informasi dan/atau data pribadi yang terkait dengan akun Gaspoll Anda, sepanjang diperlukan oleh hukum yang berlaku.</li>
        </ul>
        <p>
          Dengan mengirimkan permintaan ini, Anda mengkonfirmasi bahwa:
        </p>
        <ul>
          <li>Anda adalah pemilik akun yang bersangkutan,</li>
          <li>Anda telah membaca dan memahami konsekuensi penghapusan akun Gaspoll sebagaimana telah dijelaskan di atas, dan</li>
          <li>Anda setuju untuk memberikan izin kepada kami untuk menghapus akun Gaspoll Anda.</li>
        </ul>
        <p>
          Kami berhak untuk menolak permintaan penghapusan akun Anda jika diizinkan atau diperlukan berdasarkan hukum yang berlaku. Ini termasuk situasi di mana kami menganggap permintaan tersebut tidak relevan, tidak serius atau mengada-ada, atau jika terkait dengan pelanggaran ketentuan penggunaan atau kegiatan yang melawan hukum.
        </p>
        <p>
          Email konfirmasi akan dikirimkan setelah akun Gaspoll Anda berhasil dihapus.
        </p>

        {/* Checkbox for consent */}
        <div style={{ marginBottom: '10px' }}>
          <Checkbox
            checked={isAgreed}
            onChange={handleCheckboxChange}
          >
            Setuju? Silakan setujui untuk melanjutkan.
          </Checkbox>
        </div>

        {/* Conditionally render text */}
        {!isAgreed && (
          <p style={{ color: 'red' }}>
            Silakan setujui untuk melanjutkan.
          </p>
        )}
      </Card>
    </div>
  )
}

export default NodePengemudi2
