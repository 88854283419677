import { Card } from "antd";
import React from "react";

const NotePelanggan = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%", padding: "20px" }}>
      <Card
        title="Penghapusan akun melalui aplikasi Gojek atau GoPay"
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "20px",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          margin: "0 auto",
          width: "100%",
          maxWidth: "600px", // Maksimal lebar untuk desktop
          marginBottom: "15px",
          textAlign: "left",
          fontSize: '12px',
        }}
      >
        <p>Untuk menghapus akun melalui aplikasi Gojek, ikuti cara berikut:</p>
        <ul>
          <li>Pada beranda aplikasi, ketuk ikon Profil Saya di pojok kanan atas</li>
          <li>Pilih menu Pengaturan akun</li>
          <li>Pilih Hapus akun, centang persetujuan, lalu ketuk Lanjut</li>
        </ul>
        <p>
          <strong>Untuk menghapus akun melalui aplikasi GoPay, ikuti cara berikut:</strong>
        </p>
        <ul>
          <li>Pada beranda aplikasi, ketuk ikon Profil Saya di pojok kiri atas</li>
          <li>Pilih menu Pengaturan akun & aplikasi</li>
          <li>Pilih Hapus atau nonaktifkan akun</li>
        </ul>
      </Card>
    </div>
  );
};

export default NotePelanggan;
