import React, { useState } from 'react';
import { Card, Checkbox } from 'antd';

const NoteMitraUsaha = () => {
  const [isAgreed, setIsAgreed] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsAgreed(e.target.checked);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px", width: "100%" }}>
      <Card
        title="Pernyataan Persetujuan (Mitra Usaha)"
        style={{
          maxWidth: '600px', // Max width for large screens
          width: '100%', // Ensures card is responsive
          margin: '20px auto', // Centers the card
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          textAlign: 'left', // Align text to left for better readability
          fontSize: '12px',
        }}
      >
        <p>
          Mohon diperhatikan bahwa dengan menghapus akun Desa Kuliner Merchant Anda:
        </p>
        <ul>
          <li>
            Anda tidak akan lagi dapat mengakses fitur dan layanan yang ditawarkan melalui platform Desa Kuliner Merchant. Oleh karena itu, Anda mungkin ingin menyimpan catatan riwayat transaksi Anda dalam aplikasi Desa Kuliner Merchant sebelum melanjutkan proses penghapusan akun.
          </li>
          <li>
            Anda akan kehilangan semua voucher atau saldo yang belum digunakan dalam akun Desa Kuliner Merchant Anda, yang tidak dapat dipulihkan, ditransfer ke akun Desa Kuliner Merchant lain, dan diuangkan.
          </li>
          <li>
            Jika Anda ingin menggunakan aplikasi Desa Kuliner Merchant lagi setelah menghapus akun Anda, Anda akan diminta untuk mendaftar akun baru.
          </li>
          <li>
            Kami berhak menyimpan beberapa informasi dan/atau data pribadi yang terkait dengan akun Desa Kuliner Merchant Anda, sepanjang diperlukan oleh hukum yang berlaku.
          </li>
        </ul>
        <p>
          Dengan mengirimkan permintaan ini, Anda mengkonfirmasi bahwa:
        </p>
        <ul>
          <li>Anda adalah pemilik akun yang bersangkutan,</li>
          <li>
            Anda telah membaca dan memahami konsekuensi penghapusan akun Desa Kuliner Merchant sebagaimana telah dijelaskan di atas, dan
          </li>
          <li>
            Anda setuju untuk memberikan izin kepada kami untuk menghapus akun Desa Kuliner Merchant Anda.
          </li>
        </ul>
        <p>
          Kami berhak untuk menolak permintaan penghapusan akun Anda jika diizinkan atau diperlukan berdasarkan hukum yang berlaku. Ini termasuk situasi di mana kami menganggap permintaan tersebut tidak relevan, tidak serius atau mengada-ada, atau jika terkait dengan pelanggaran ketentuan penggunaan atau kegiatan yang melawan hukum.
        </p>
        <p>
          Email konfirmasi akan dikirimkan setelah akun GoBiz dan GoPay Merchant Anda berhasil dihapus.
        </p>

        {/* Checkbox for consent */}
        <div style={{ marginBottom: '10px' }}>
          <Checkbox
            checked={isAgreed}
            onChange={handleCheckboxChange}
          >
            Setuju? Silakan setujui untuk melanjutkan.
          </Checkbox>
        </div>

        {/* Conditionally render text */}
        {!isAgreed && (
          <p style={{ color: 'red' }}>
            Silakan setujui untuk melanjutkan.
          </p>
        )}
      </Card>
    </div>
  );
};

export default NoteMitraUsaha;
