import React from "react";
import { Card } from "antd";

const NodePengemudi = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Card
        title="Penghapusan akun melalui aplikasi Gaspoll"
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "20px",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          margin: "0 auto",
          width: "100%",
          maxWidth: "600px", // Lebar maksimum untuk desktop
          marginBottom: "15px",
          textAlign: "left",
          fontSize: '12px',
        }}
      >
        <p>Untuk menghapus akun melalui aplikasi Gaspoll, ikuti cara berikut:</p>
        <ol style={{ paddingLeft: "20px" }}>
          <li>
            Pada beranda aplikasi, ketuk ikon <strong>Profil Saya</strong> di pojok kanan atas.
          </li>
          <li>Pilih menu <strong>Pengaturan akun</strong>.</li>
          <li>
            Pilih <strong>Hapus akun</strong>, centang persetujuan, lalu ketuk <strong>Lanjut</strong>.
          </li>
        </ol>
      </Card>
    </div>
  );
};

export default NodePengemudi;
